@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

// Search Input
.searchInputContainer {
  $input-border: 1px solid variables.$ht-navy-200;
  $top-nav-height: 0;

  justify-content: space-between;
  display: flex;
  position: sticky;
  top: $top-nav-height;
  background-color: variables.$ht-white;
  border-top: $input-border;
  border-bottom: $input-border;
  @include spacing.spacing(padding-top, 3.75);
  @include spacing.spacing(padding-bottom, 3.75);
}
.gridColumn {
  display: flex;
  align-items: center;
}
.searchInput {
  width: 100%;
  border: none;
  outline: none;
  &:focus,
  &:focus-visible,
  &:focus-within {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: variables.$ht-navy-300;
  }
}

// Search Results
.searchResultsContainer {
  overflow-y: auto;
}
