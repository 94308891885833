@use 'ht-styleguide/build/variables';

.panel {
  border: 1px solid variables.$ht-navy-200;
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
}

.valueIcon {
  font-size: 20px;
  line-height: inherit;
  vertical-align: bottom;
}
