@use 'ht-styleguide/build/grid';

$ht-component-grid-margin: 32px;

.grid {
  @include grid.use-custom-margins($ht-component-grid-margin !important); // use important to overcome default grid styles
}
.gridRow {
  @include grid.use-custom-gutters(20px, !important); // use important to overcome default grid styles
}

/**
 * Use this class on a child of a grid to escape from its side padding.
 * This escapes the padding of this component's grid parent and not from the
 * grid in the styleguide.
 */
:global(.Components-Grid_escapeMargin) {
  margin-left: -$ht-component-grid-margin;
  margin-right: -$ht-component-grid-margin;
}
