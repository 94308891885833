@use 'sass:map';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

$highlight-color: rgba(variables.$ht-teal-500, 0.08);

/******************************************
  TableToolbarLink
*******************************************/
.tableToolbarLink {
  @include spacing.spacing(padding-right, 2 !important);
  @include spacing.spacing(padding-left, 2 !important);

  border: none !important;
}

/******************************************
  TableToolbarLinkWithMenu
*******************************************/
.menuPosition {
  left: -70px;
  top: 40px;
}

/******************************************
  TableToolbar
*******************************************/
.filterLinkActive {
  background-color: $highlight-color !important;
}

.chipDisplayContainer {
  background-color: $highlight-color;
  border-radius: map.get(variables.$ht-panel-border-radius, large);
}

.filterChip {
  @include spacing.spacing(margin-right, 1);
  @include spacing.spacing(margin-bottom, 0.5);
  @include spacing.spacing(margin-top, 0.5);
  @include spacing.spacing(padding, 1);

  background-color: variables.$ht-white;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  border-radius: map.get(variables.$ht-panel-border-radius, medium);
  border: 1px solid variables.$ht-navy-300;
}

.filterChipCloseIcon {
  @include spacing.spacing(margin-left, 1);

  color: variables.$ht-navy-700;
  font-size: 10px;
}

.applyFiltersContainer {
  @include spacing.spacing(margin-left, 10);

  align-items: flex-start;
}

.applyFiltersCtaButton {
  border: none !important;
}
