@use 'sass:map';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

@mixin isActiveState() {
  background-color: rgba(variables.$ht-teal-500, 0.08);
  color: variables.$ht-teal-500;
  border-radius: map.get(variables.$ht-panel-border-radius, large);
}
@mixin isHoverState() {
  color: variables.$ht-teal-500;
}

.header {
  z-index: 10; // Need this so the logout menu always appears on top
  display: flex;
  justify-content: space-between;
  @include spacing.spacing(padding, 2.5 4);

  position: sticky;
  top: 0;
  background-color: variables.$ht-white;
  border: 1px solid variables.$ht-navy-200;
}

.htIconContainer {
  border-right: 1px solid variables.$ht-navy-300;
  display: flex;
  align-items: center;
  height: 40px;
}

.icon {
  font-size: 20px;
}

.actionIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  @include spacing.spacing(margin-right, 2);

  cursor: pointer;
  &.isActive {
    @include isActiveState();
  }
  &:hover {
    @include isHoverState();
  }
  &.noRightMargin {
    margin-right: 0;
  }
}

.navItem {
  cursor: pointer;
  background-color: transparent;
  border: none;
  @include spacing.spacing(padding, 1 1.75);
  &:last-of-type {
    margin-right: 0;
  }
  &.isActive {
    @include isActiveState();
  }
  &:hover {
    @include isHoverState();
  }
}

.iconItemMenuPanel {
  position: absolute;
  right: 0;
  top: 50px;
}
