@use 'ht-styleguide/build/variables';

.pageButtonWrapper {
  &.inactive {
    pointer-events: none;
  }
}

.pageIcon {
  font-size: 20px;
  color: variables.$ht-navy-700;

  &:hover {
    cursor: pointer;
  }

  &.inactive {
    color: variables.$ht-navy-300;
    pointer-events: none;
  }
}

.pageInput {
  /*
    For some reason, the outer flex is causing random width changes. We'll lock in the width here.
  */
  width: 70px;
}

.selectOuterWrapper {
  /*
    For some reason, the outer flex div is causing <SelectField />'s width to collapse. We're
    expliciting setting width here to fix that.
  */
  min-width: 90px;
}
