@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.header {
  .subtitle {
    opacity: 0.6;
  }

  .actions {
    cursor: pointer;
    @include spacing.spacing(padding-bottom, 2);
  }
}

.backIcon {
  color: variables.$ht-navy-000;
  font-size: 10px;
}
