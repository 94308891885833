.imageContainer {
  height: 48px !important;
  width: 48px !important;

  // side note on icon. can't really adjust the most inner icon because its "inline". more
  // integrated change would need to happen within the component proper.
}

.commentContainer {
  width: 450px;
}
