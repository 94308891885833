@use 'sass:map';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

$carousel-button-icon-adjust: 1px;
$thumbnail-border-color: variables.$ht-navy-200;

// Sizes
$carousel-button-size: 32px;
$thumbnail-action-button-size: 28px;
$thumbnail-action-button-mini-size: 24px;
$thumbnail-mini-size: 48px; // height && width

// Z-indexes
$scrim-overlay-hover-z-index: 2;
$play-button-z-index-mini: 1; // Play button should be behind the scrim
$play-button-z-index-default: 4; // Play button should stack on top of the scrim
$action-button-z-index: 3;
$tooltip-z-index: 5000;

.carouselButton {
  position: absolute;
  top: 35%;
  width: $carousel-button-size;
  height: $carousel-button-size;
  border-radius: 50%;
  box-shadow: variables.$ht-box-shadow-medium;
  border: 1px solid variables.$ht-navy-300;
  background-color: variables.$ht-navy-000;
  display: flex;
  justify-content: center;
  @include spacing.spacing(padding, 1);
}

.thumbnailCarouselContainer {
  position: relative;
  overflow: hidden;
  .carouselButton {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
  }
  .carouselButton.visible {
    visibility: visible;
    opacity: 1;
  }
}
.thumbnailCarousel {
  display: flex;
  transition: transform 0.3s ease;
  margin-right: -8px;
}

.thumbnailOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: variables.$ht-navy-900;
  opacity: 0;
  pointer-events: none;
  &.thumbnailOverlayVideo {
    opacity: 0.3;
  }
}

.thumbnailActionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  width: $thumbnail-action-button-size;
  height: $thumbnail-action-button-size;
  border-radius: map.get(variables.$ht-panel-border-radius, medium);
  background-color: #9da2a9;
  color: variables.$ht-navy-000;
  @include spacing.spacing(margin-top, 1);
  @include spacing.spacing(margin-right, 1);
  &:hover {
    background-color: transparent;
    border: 1px solid variables.$ht-navy-000;
  }
}

.thumbnail {
  cursor: pointer;
  width: 160px;
  height: 112px;
  border: 1px solid $thumbnail-border-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: map.get(variables.$ht-panel-border-radius, large);
  margin-right: 8px;
  overflow: hidden;
  &:hover .thumbnailOverlay {
    opacity: 0.6;
    z-index: $scrim-overlay-hover-z-index;
  }
  &:hover .thumbnailActionButton {
    opacity: 1;
  }

  flex: none;
}

/* stylelint-disable no-descending-specificity */
.thumbnailMini {
  width: $thumbnail-mini-size;
  height: $thumbnail-mini-size;
  .thumbnailActionButton {
    width: $thumbnail-action-button-mini-size;
    height: $thumbnail-action-button-mini-size;
    @include spacing.spacing(margin-top, 0.75);
    @include spacing.spacing(margin-right, 0.75);
  }
  .thumbnailPlayButtonIcon {
    z-index: $play-button-z-index-mini;
  }
}

.thumbnailImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnailIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thumbnailContent {
  position: relative;
  flex: 1;
  background-color: variables.$ht-navy-050;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}

.thumbnailButtonContainer {
  position: absolute;
  z-index: $action-button-z-index;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.thumbnailFileName {
  height: 32px;
  background-color: variables.$ht-navy-000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @include spacing.spacing(padding, 1 2);

  border-top: 1px solid $thumbnail-border-color;

  /** Need to match the border radius of the .thumbnail  to avoid visual distortion */
  border-bottom-left-radius: map.get(variables.$ht-panel-border-radius, large);
  border-bottom-right-radius: map.get(variables.$ht-panel-border-radius, large);
}

.thumbnailActionButtonIcon {
  font-size: 20px;
}

.thumbnailPlayButtonIcon {
  font-size: 28px;
  color: variables.$ht-white;
  position: absolute;
  z-index: $play-button-z-index-default;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.thumbnailNonImageDisplay {
  position: absolute;
  z-index: $play-button-z-index-default;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: rgba(variables.$ht-teal-100, 0.8);
    border-radius: 50%;
    z-index: -1;
  }

  > .icon {
    z-index: 1;
    font-size: 28px;
    color: variables.$ht-teal-700;
  }
}

.carouselButtonIcon {
  font-size: 16px;
  position: relative;
  &.left {
    left: -$carousel-button-icon-adjust;
  }
  &.right {
    right: -$carousel-button-icon-adjust;
  }
}

.thumbnailTooltip {
  position: fixed;
  z-index: $tooltip-z-index;
  pointer-events: none;

  /** These styles are lifted from Tooltip in the styleguide */
  background-color: variables.$ht-navy-050;
  border-radius: 2px;
  border: 1px solid variables.$ht-navy-300;
  box-shadow: variables.$ht-box-shadow-high;
  @include spacing.spacing(padding, 1 1.5);

  text-align: center;
  transform: translateX(-50%);
}
