@use 'src/styles/global/variables';

.panel {
  border: 1px solid variables.$ht-navy-200;
}
.addBillingLead {
  width: 56px;
  height: 56px;
  border: 3px dashed variables.$ht-navy-300;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleIcon {
  font-size: 18px;
}
