@use 'src/styles/global/variables';
@use 'ht-styleguide/build/typography_placeholders';

:global {
  @import '~yet-another-react-lightbox/styles.css';
  @import '~yet-another-react-lightbox/plugins/captions.css';
  @import '~yet-another-react-lightbox/plugins/thumbnails.css';

  .yarl__slide_title_container > .yarl__slide_title {
    @extend %h6;

    color: variables.$ht-white;
  }

  .yarl__slide_description {
    @extend %h6;

    color: variables.$ht-white;
  }
}
