@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.alignRightFlagged {
  display: flex;
  justify-content: flex-end;

  @include spacing.spacing(padding-right, 2);
}

/* Callout Box */
.calloutBox {
  @include spacing.spacing(margin-bottom, 2);

  border-bottom: solid 1px variables.$ht-navy-200;
}

/* Services Display */
.unitServicesContainer {
  border-radius: 4px;
  border: solid 1px variables.$ht-navy-200;
  background-color: variables.$ht-navy-050;

  @include spacing.spacing(padding, 4);
}

.servicesStatusContainer_right {
  width: 70px;
}

.successIcon {
  font-size: 20px;
  color: variables.$ht-success-green-700;
}

.inErrorIcon {
  font-size: 20px;
  color: variables.$ht-alert-red-700;
}
.techAppLink {
  color: variables.$ht-navy-700 !important;
  text-decoration: underline;
}

.menuPositionCreate {
  top: 40px;
  width: 200px;
  left: 0;
}

.menuPositionCreatePlainButton {
  top: 30px;
  width: 200px;
}

.menuPositionCreateButton {
  top: 50px;
  width: 200px;
}

.emptyContainerWidth {
  width: 500px;
}
