/* stylelint-disable no-descending-specificity */

@use 'ht-styleguide/build/spacing';
@use 'ht-styleguide/build/typography_placeholders';
@use 'ht-styleguide/build/variables';

$cell-side-padding: 2;
$border-style: 1px solid variables.$ht-navy-200;

:global(.MuiTableContainer-root),
:global(.MuiPopover-root) {
  & * {
    font-family: variables.$ht-font-family;
  }
}

:global(.MuiTableContainer-root) {
  /*
      NOTE: Retaining this block for future notes. We've commented out the overflow property
            because the overflowing of the table container on the page was more of an issue
            than the ones listed below. We may need to revisit this in the future.

      Setting overflow to revert will remove the MRT's nested scrollbars. This was done for 2 reasons.
      1.  HT Sticky header - The design team wanted the table's header row to stick in a different way
          that MRT's sticky functionality provided. To acheieve's HT's sticky header, the table's header
          row was set to position: sticky. The header needs to stick to the main content area, but with
          MRT's nested scrollbars, the header would stick to the top of the table's container instead.
          So, the overflow property must be reverted.
      2.  ActionMenu - The ActionMenu component would create scrollbars when opened. Overflow on
          the table container must be reverted so the menu can overflow the table.
    */
  // overflow: revert;
  border-top: $border-style;
  border-left: $border-style;
  border-right: $border-style;

  // Need to set z-index to 0 to allow the sticky header to be on top of the table container.
  // Otherwise, pinned columns will overlap the sticky header
  position: relative;
  z-index: 1;
}

:global(.MuiTableHead-root) {
  &.htStickyHeader {
    position: sticky;
    z-index: 2;
  }
}

:global(.MuiTableRow-head) {
  box-shadow: none;
}

:global(.MuiTableCell-root) {
  background-color: inherit;
  border-bottom: $border-style;
  border-right: $border-style;
  @include spacing.spacing(padding, 2.5 $cell-side-padding);

  // Allow action menu to overflow the cell
  overflow: unset;
  vertical-align: middle;

  &.leftPinnedColumn {
    // remove MRT styles
    box-shadow: none;
    // HT styles
    border-right: $border-style;
  }

  &.rightPinnedColumn {
    // remove MRT styles
    box-shadow: none;
    // HT styles
    border-left: $border-style;
  }

  &.groupedCell {
    @extend %overline-medium;

    color: variables.$ht-navy-800;
    font-weight: variables.$ht-font-weight-medium;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &.groupedCellInRow {
    @include spacing.spacing(padding, 1 $cell-side-padding);
  }
}

:global(.MuiBadge-root) {
  @include spacing.spacing(padding-right, 0.5);
}

:global(.MuiBadge-badge) {
  @extend %caption;

  color: variables.$ht-navy-700;
  top: calc(50% - 3px);
}

:global(.MuiTableCell-head) {
  // one-off
  font-size: 14px;
  letter-spacing: -0.35px;
  line-height: 1.429; // 20px/14px
  color: variables.$ht-navy-900;
  font-weight: variables.$ht-font-weight-medium;

  &:hover {
    :global(.Mui-TableHeadCell-Content .Mui-TableHeadCell-Content-Actions .MuiIconButton-root).dragHandle {
      opacity: 1;
    }
  }
}

:global(.Mui-TableHeadCell-Content-Wrapper) {
  white-space: nowrap;
  min-width: auto;
}

:global(.Mui-TableHeadCell-Content) {
  justify-content: space-between;

  :global(.Mui-TableHeadCell-Content-Actions .MuiIconButton-root).dragHandle {
    color: variables.$ht-navy-300;
    opacity: 0;
  }

  :global(.MuiDivider-root) {
    border-color: variables.$ht-navy-300;
  }
}

:global(.Mui-TableHeadCell-ResizeHandle-Wrapper) {
  right: -(variables.$ht-base-unit * $cell-side-padding + 2 + 2); // just eyeballing here - 2 for padding, 2 for border
  padding-left: 2px;
  padding-right: 2px;
  margin-right: 0;
  opacity: 0;
}

:global(.MuiTableBody-root) {
  :global(.MuiTableRow-root) {
    background-color: variables.$ht-white;

    &:hover {
      td {
        background-color: inherit;
      }
    }

    &.actionableRow {
      &:hover {
        cursor: pointer;
        background-color: variables.$ht-navy-100;
        :global(.MuiTableCell-root) {
          background-color: inherit;
        }
      }
    }

    &.zebra {
      &:nth-of-type(odd) {
        background-color: variables.$ht-white;
      }
      &:nth-of-type(even) {
        background-color: variables.$ht-navy-100;
      }

      &:hover {
        background-color: variables.$ht-navy-100;
      }
    }

    &.groupSubheader {
      background-color: variables.$ht-navy-200;
    }
  }
}

:global(.MuiTableCell-body) {
  white-space: normal;
}

:global(.MuiCheckbox-root) {
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }

  &.groupedCheckbox {
    pointer-events: none;
    visibility: hidden;
  }

  &:global(.Mui-checked),
  &:global(.MuiCheckbox-indeterminate) {
    color: variables.$ht-blue-700;
    opacity: 1;
  }
}

:global(.Mui-TableBodyCell-DetailPanel) {
  border: none;
  padding: 0;
}

/*
  Using MRT's align prop will move only the text. For example, aligning right will move the text to the
  right of the sort icon.
*/
:global(.DataTable-TableHeader_alignRight) {
  :global(.Mui-TableHeadCell-Content) {
    justify-content: right;
  }
}

.compact {
  :global(.MuiTableCell-root) {
    @include spacing.spacing(padding, 1.25);
  }

  :global(.Mui-TableHeadCell-ResizeHandle-Wrapper) {
    right: -(variables.$ht-base-unit * 1.25 + 2 + 2); // just eyeballing here - 2 for padding, 2 for border
  }

  :global(.Mui-TableBodyCell-DetailPanel) {
    padding: 0;
  }
}

.transparentBG {
  :global(.MuiPaper-root) {
    background-color: transparent;
  }
  :global(.MuiTableHead-root) {
    :global(.MuiTableRow-root) {
      background-color: transparent;
    }
  }
  :global(.MuiTableBody-root) {
    :global(.MuiTableRow-root) {
      background-color: transparent;
    }
  }
}

// Only applies to `customRowSelectColumn` util
.customCheckboxCell {
  max-width: 40px;
  width: 40px;
  min-width: 40px;
  // Header row
  :global(.Mui-TableHeadCell-Content) {
    justify-content: center;
  }
  // Body rows - see .checkboxClassesOverride
}

.expandIconCell {
  text-align: center;
  max-width: 48px; // Match with EXPAND_COLUMN_WIDTH
  width: 48px;
  min-width: 48px;

  // Header row
  :global(.Mui-TableHeadCell-Content) {
    justify-content: center;
  }
}
