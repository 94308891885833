@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

/* stylelint-disable selector-pseudo-class-no-unknown */
.placeholder {
  :global {
    .react-select {
      &__placeholder {
        color: variables.$ht-navy-300;
      }
    }
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(0.9);
  }
  input[type='date'],
  input::placeholder,
  textarea::placeholder {
    color: variables.$ht-navy-300;
  }
}

.emptyStateButtonContainer {
  display: flex;
  justify-content: center;

  > button {
    width: auto;
  }
}

.fullPageTakeOver {
  position: fixed;
  z-index: 11; // Needs to be higher than the z-index of AdminHub layout's header
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;

  @include spacing.spacing(padding, 10);

  background-color: variables.$ht-white;
}
