@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/_variables';

$page-status-bar-green-base-color: variables.$ht-teal-500;

.container {
  &.tealGreen {
    background-color: $page-status-bar-green-base-color;
  }
  &.neutral {
    background-color: variables.$ht-navy-700;
  }
}

// Since button color is hardcoded for BUTTON_THEMES.INVERTED, we need to override
// to match the color to the background it sits in.
.buttonInvertedOverride {
  color: $page-status-bar-green-base-color !important;
}

.linkText {
  overflow: hidden;
  max-width: 260px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rightComponent {
  & + .rightComponent {
    margin-left: spacing.spacing-value(small2, 'd');
  }
}
