.mainContentWrapper {
  overflow: hidden;
  display: flex;
}

// The children of this wrapper should have height: 100% and overflow set to auto to allow for scrolling
.scrollContentsWrapper {
  overflow: hidden;
  display: flex;
  flex: 1;
}
