@use 'sass:map';
@use 'src/styles/global/variables';

.editMenu {
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: left;
  z-index: 1;
  li {
    display: flex;
    align-items: center;
    white-space: nowrap;
    list-style: none;
    border-radius: map.get(variables.$ht-panel-border-radius, large);
    cursor: pointer;

    &:hover {
      background-color: variables.$ht-navy-100;
      color: variables.$ht-navy-900;
    }
  }
}

.callout_group {
  border-top: 1px solid variables.$ht-navy-200;
  border-radius: 0;
}
