@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.dragDropField {
  border: 1px dashed variables.$ht-navy-300;
  @include spacing.spacing(padding-top, 1.5);
  @include spacing.spacing(padding-bottom, 1.5);

  cursor: pointer;
}
