@use 'src/styles/global/variables';

.outerContainer {
  display: table;
  table-layout: fixed;
  overflow-x: hidden;
  width: 100%;
  background-color: variables.$ht-navy-050;
  box-shadow: 0 0 8px 2px rgba(variables.$ht-navy-900, 0.07) inset;
}

.innerContainer {
  width: 100%;
  overflow-x: auto;
}

.placeholderColumn {
  display: table-cell;
  border-right: 1px solid variables.$ht-navy-200;
}

.childrenColumn {
  display: table-cell;
  width: 100%;
}
