.modalClass {
  overflow-x: hidden;
}

.clearAllWrapper {
  height: 100%;
}

.clearAll {
  white-space: nowrap;
}
