@use 'src/styles/global/variables';

/* Icon Close In Input */
.closeIcon {
  font-size: 16px !important;
  background-color: variables.$ht-white;
  padding: 32px 12px;
  margin-right: -12px;
}

/* stylelint-disable selector-pseudo-class-no-unknown */
.placeholder {
  :global {
    .react-select {
      &__placeholder {
        color: variables.$ht-navy-300;
      }
    }
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(0.9);
  }
  input[type='date'],
  input::placeholder,
  textarea::placeholder {
    color: variables.$ht-navy-300;
  }
}
