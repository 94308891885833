@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.outerContainer {
  &.isSticky {
    position: sticky; // needs to stick to AdminLayout.ContentRightContainer
    top: 0;
    z-index: 2; // 2 is random value for now
  }
}

// Need this outer container otherwise the background color will not extend to the full width of the screen
// if using <BaseGrid.Fluid />
.outerHeaderContainer {
  background-color: variables.$ht-navy-000;
}

.mainHeaderContainer {
  height: 80px;
}

.titleContainer {
  & > * {
    margin-right: spacing.spacing-value(tiny1, 'd');
  }
}

.mainHeaderRightContainer {
  & > * {
    margin-left: spacing.spacing-value(small, 'd');
  }
}

.collapseIcon {
  cursor: pointer;
  font-size: 20px;
  color: variables.$ht-navy-700;
}

/******************************************
  Items
*******************************************/
.textLinkButton {
  @include spacing.spacing(padding-right, 0 !important);
  @include spacing.spacing(padding-left, 0 !important);

  border: none !important;
}

.icon {
  font-size: 20px;
  color: variables.$ht-navy-700;
}
