@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.editMenuContainer {
  cursor: pointer;
  position: relative;
  @include spacing.spacing(padding, 0 0.5);
}

.editIcon {
  color: variables.$ht-navy-900;
  font-size: 18px;
  display: block;
  &.disabled {
    color: variables.$ht-navy-300;
  }
  &.compact {
    font-size: 16px;
  }
}

.editMenu {
  position: absolute;
  top: 32px;
  right: -12px;
  text-align: left;
  z-index: 1;

  &.compact {
    top: 24px;
    right: -8px;
  }

  li {
    min-width: 130px;
    white-space: nowrap;
    list-style: none;
    @include spacing.spacing(padding, 1.5 2.5);

    cursor: pointer;
    &:hover {
      background-color: variables.$ht-navy-100;
    }
  }
}
