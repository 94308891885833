@use 'ht-styleguide/build/variables';

.iconPriority {
  size: 16px;
}

.high,
.critical {
  color: variables.$ht-alert-red-700;
}
.medium {
  color: variables.$ht-deep-yellow-700;
}
.low {
  color: variables.$ht-blue-700;
}
