@use 'sass:map';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

$ht-metagrid-border: 1px solid variables.$ht-navy-200;

.container {
  border: $ht-metagrid-border;
  border-radius: map.get(variables.$ht-panel-border-radius, 'large');
}

.row {
  display: grid;
  border-bottom: $ht-metagrid-border;

  &:last-of-type {
    border-bottom: none;
  }
}

.item {
  @include spacing.spacing(padding, 1.5);

  border-right: $ht-metagrid-border;

  &:last-of-type {
    border-right: none;
  }
}
