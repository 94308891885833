@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.breakdownContainer {
  border-radius: 4px;
  border: solid 1px variables.$ht-navy-200;
  background-color: variables.$ht-navy-050;

  @include spacing.spacing(padding, 4);
}
