@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.groupContainer {
  background-color: variables.$ht-navy-050;
  border: 1px solid variables.$ht-navy-100;
  border-radius: 5px;
  width: 100%;

  @include spacing.spacing(padding, 2);
}

.quantity {
  width: 25px;
}

.templateName {
  flex: 1;
}

.name {
  width: 100%;
  text-align: center;
}
