@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

// FYI: Don't add a height here
.link_editor {
  display: flex;
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 10;
  width: 300px;
  opacity: 1;
  background-color: variables.$ht-white;
  border: 1px solid variables.$ht-navy-200;
  box-shadow: variables.$ht-box-shadow-low;
  border-radius: 4px;
  transition: opacity 0.5s;
  will-change: transform;
}

.link_editor:empty {
  border: unset;
}

.link_editor .link_input {
  display: block;
  width: 100%;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
  position: relative;
  font-family: inherit;
  align-items: center;

  @include spacing.spacing(padding, 2 1);
}

.link_editor .link_input_clean {
  border: solid 1px variables.$ht-blue-700;
}

.link_editor .link_input_error {
  border: solid 1px variables.$ht-alert-red-700;
}

.link_editor .link_view {
  display: flex;
  justify-content: space-between;
  width: 300px;
  border-radius: 4px;
  font-size: 15px;
  color: variables.$ht-navy-900;
  border: 0;
  outline: 0;
  position: relative;
  font-family: inherit;
  height: 30px;
  align-items: center;

  @include spacing.spacing(margin, 1 1.5);

  div:first-child {
    width: 100%;
  }

  [class*='ht-v2-close-icon'] {
    font-size: 12px;
  }
}

.link_editor .link_view a {
  display: block;
  word-break: break-word;
  width: calc(100% - 33px);
}

.base_link_container {
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.link_editor div.link_edit {
  @extend .base_link_container;

  right: 30px;
}

.link_editor div.link_trash {
  @extend .base_link_container;
}

.link_editor div.link_cancel {
  @extend .base_link_container;

  margin-right: 28px;
  font-size: 12px;
}

.link_editor div.link_confirm {
  @extend .base_link_container;

  margin-right: 2px;
}

.link_editor .link_input a {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 30px;
  text-overflow: ellipsis;
}

.link_editor .link_input a:hover {
  text-decoration: underline;
}

.link_editor .font-size-wrapper,
.link_editor .font-family-wrapper {
  display: flex;
  margin: 0 4px;
}

.link_editor select {
  border: none;
  background-color: rgba(0, 0, 0, 0.075);
  border-radius: 4px;

  @include spacing.spacing(padding, 1);
}

// MENTIONS
.mentions {
  z-index: variables.$ht-z-index-request-loader;

  @include spacing.spacing(margin-top, 2);
  @include spacing.spacing(margin-left, 3);

  ul {
    max-height: 200px !important;
    min-width: 318px;
    overflow: auto;
    background-color: variables.$ht-white;
    border-radius: 4px;
    border: 1px solid variables.$ht-navy-300;
    box-shadow: variables.$ht-box-shadow-low;

    @include spacing.spacing(margin-top, 1);
    @include spacing.spacing(padding-top, 1);
    @include spacing.spacing(padding-bottom, 1);

    li {
      @include spacing.spacing(padding, 1.5 2.5);
    }

    li:hover {
      cursor: pointer;
      background-color: variables.$ht-navy-200;
    }
  }
  ul:empty::before {
    content: 'User not found';
  }

  ul:empty {
    @include spacing.spacing(padding, 2.5);
  }
}

span[data-beautiful-mention*='@'] {
  background-color: variables.$ht-navy-200;
  color: variables.$ht-navy-800;

  @include spacing.spacing(padding, 0.4);

  border-radius: 8px;
  font-size: 14px !important;
}

// FOOTER PLUGIN
.footerPluginContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @include spacing.spacing(padding-top, 2);
  @include spacing.spacing(padding-bottom, 2);
  @include spacing.spacing(padding-right, 1.5);

  button {
    width: 75px;
    height: 40px;
  }

  button:first-of-type {
    @include spacing.spacing(margin-right, 2);
  }
}

.containerEmptyEditor {
  width: 100%;
  height: 40px;
  border: 1px solid variables.$ht-navy-300;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: variables.$ht-navy-300;

  @include spacing.spacing(padding-left, 1);
  @include spacing.spacing(padding-right, 1);

  .withUnsavedContent {
    color: variables.$ht-navy-700;
  }

  &:hover {
    border: 1px solid variables.$ht-navy-900;
    cursor: pointer;
  }
}

// TOOL BAR
.editorToolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid variables.$ht-navy-300;
  height: 40px;

  i {
    height: 16px;
    @include spacing.spacing(padding-right, 2);

    cursor: pointer;
  }
}
