@use 'src/styles/global/variables';
@use 'general.variables' as generalVariables;

.container {
  display: flex;
  flex-direction: column;
}

.headerBase {
  position: sticky;
  z-index: generalVariables.$general-header-z-index;
  top: 0;
  min-height: generalVariables.$general-header-height;
  width: 100%;
  background-color: variables.$ht-white;
  box-shadow: variables.$ht-box-shadow-medium;
}

.innerContainer {
  flex: 1;
  display: flex;
  min-height: 0; // force to fill only the empty space
  justify-content: center;
  width: 100%;
}

.innerPlainContainer {
  height: 100%;
  overflow-y: auto;
}
