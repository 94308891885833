@use 'ht-styleguide/build/variables';

.page {
  background-color: variables.$ht-navy-200;
  height: 100%;
  overflow: auto;
}

.pageContent {
  width: 1120px;
  margin: 0 auto;
}

.backIconContainer {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  .icon {
    font-size: 12px;
  }
}

.panel {
  border: 1px solid variables.$ht-navy-300;
  border-radius: 16px;
  background-color: variables.$ht-white;
}

/************* Styles from old component *************/

.data {
  p {
    max-width: 130px;
  }
}

.headerTable {
  width: 404px;
  th {
    width: 236px;
  }
}

.table {
  table-layout: fixed;
  width: 936px;
  tr {
    th {
      width: 186px;
    }
  }
}

.servicesTable {
  table-layout: fixed;
  width: 100%;
}

.chevronIcon {
  font-size: 16px;
}

.contact {
  table-layout: fixed;
  width: 600px;
  text-align: left;
}

.largeTableHeader {
  width: 240px;
}

.medTableHeader {
  width: 100px;
}

.smallTableHeader {
  width: 40px;
}

$cardGap: 24px;
.container_cardDetails {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 5px;
  vertical-align: top;
  width: 100%;
  column-gap: $cardGap;
  row-gap: $cardGap;

  > * {
    flex-basis: calc(50% - #{$cardGap});
    flex-grow: 1;
    box-sizing: border-box;
  }
}
