@use 'ht-styleguide/build/variables';

.borderBottom {
  border-bottom: 1px solid variables.$ht-navy-200;
}

.addPayoutsButton {
  width: fit-content !important;
}

.noWrap {
  white-space: nowrap;
}

.totalPayoutAmount {
  &.increase {
    color: variables.$ht-blue-700;
  }
  &.decrease {
    color: variables.$ht-alert-red-700;
  }
}

.panel {
  border: 1px solid variables.$ht-navy-200;
}
