@use 'src/styles/global/variables';

.header_container {
  display: flex;
  align-items: center;
  box-shadow: none;
  border-bottom: 1px solid variables.$ht-navy-200;
  min-height: 72px;
}

.headerGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.logo {
  font-size: 24px;
}
