@use 'src/styles/global/variables';

.requestLoader {
  position: fixed;
  z-index: variables.$ht-z-index-request-loader;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(variables.$ht-navy-900, 0.6);
}
