@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.container {
  display: flex;
  align-items: center;
  color: variables.$ht-navy-700;
  width: 100%;

  @include spacing.spacing(padding-right, 4);
  @include spacing.spacing(margin-right, 4);
}

.closeIcon {
  font-size: 12px !important;
  color: variables.$ht-navy-900;
}

.searchIcon {
  color: variables.$ht-navy-300;
  font-size: 16px;
  @include spacing.spacing(padding-bottom, 0.5);
  &.focus {
    color: variables.$ht-navy-900;
  }
}

.inputContainer {
  width: 100%;
}

.input_override {
  flex: 1;
  border: none !important;
}
