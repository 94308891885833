@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.cardContainer {
  border-radius: 8px;
  border: 1px solid variables.$ht-navy-200;

  @include spacing.spacing(padding-left, 3);
  @include spacing.spacing(padding-right, 3);

  > div:not(:first-child) {
    border-top: 1px solid variables.$ht-navy-200;
  }
}

$cardGap: 24px;
.cardDetails {
  display: inline-flex;
  flex-wrap: wrap;
  vertical-align: top;
  width: 100%;
  column-gap: $cardGap;
  row-gap: $cardGap;

  > * {
    flex-basis: calc(33% - #{$cardGap});
    flex-grow: 1;
    box-sizing: border-box;
  }
}
