@use 'ht-styleguide/build/variables';

.panel {
  &.withBorder {
    border: 1px solid variables.$ht-navy-200;
  }
}

.avatarEditor {
  height: 32px;
  width: 32px;
}
