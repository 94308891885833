@forward 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/variables';

// z-index for FIXED components
$ht-z-index-request-loader: 2000;

// Smart Home color theme
$theme-smart-home: (
  fontcolor: linear-gradient(to bottom right, variables.$ht-blue-700, variables.$ht-blue-050),
  background: linear-gradient(to bottom right, variables.$ht-blue-700, variables.$ht-blue-050),
);

// One off colors that stemmed from MDU + other features
$subdued-blue: #87aed9;
$neon-purple: #e47efd;
$deep-purple: #553fdc;
