@use 'sass:map';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

$z-index-base: 1;
$z-index-overlay: 2;

/* Ensures the child container appears under the overlay */
.childContainer {
  z-index: $z-index-base;
  position: relative;
}

/* Styles for the dropzone overlay when dragging over */
.overlayContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(244, 248, 252, 0.3);
  z-index: $z-index-overlay;
  border: 2px solid variables.$ht-teal-500;
  border-radius: map.get(variables.$ht-panel-border-radius, small);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.panel {
  width: 272px;
  height: 128px;
  text-align: center;
  @include spacing.spacing(margin-bottom, 7.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid variables.$ht-navy-300;
}

/* Styles for the upload icon */
.icon {
  font-size: 24px;
}
