@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

.bodyContainer {
  overflow: initial;
}

.flexInitial {
  flex: initial !important;
}

.container {
  &.modalContents {
    width: 650px !important;
    max-width: 650px !important;
  }
}

.overflowInitial {
  overflow: initial !important;
}

// Payment Adjustment Modal
.payAdjustButtonContainer {
  border: 1px solid variables.$ht-navy-300;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
}

.payAdjustButton {
  width: 74px;
  height: 100%;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &.increase {
    background-color: variables.$ht-white;
    color: variables.$ht-blue-700;
    &.active {
      background-color: variables.$ht-blue-700;
      color: variables.$ht-white;
    }
  }
  &.decrease {
    background-color: variables.$ht-white;
    color: variables.$ht-alert-red-700;
    &.active {
      background-color: variables.$ht-alert-red-700;
      color: variables.$ht-white;
    }
  }
}

.payAdjustInput {
  flex: 1;
}

.teamMemberLineItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid variables.$ht-navy-200;
  padding-top: spacing.spacing-value(small, d);
  padding-bottom: spacing.spacing-value(small, d);
}

.selectedTechsList {
  min-height: 172px; // This is the height of the empty state. Use min-height to avoid jitter when adding/removing
}

input::placeholder {
  color: variables.$ht-navy-300;
}

.iconComponent {
  white-space: nowrap;
}

.overflowVisible {
  overflow: visible;
}

.icon32 {
  font-size: 32px;
  color: variables.$ht-navy-300;

  @include spacing.spacing(padding-right, 2);
}
