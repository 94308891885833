@use 'src/styles/global/variables';

.autocompleteContainer {
  overflow: hidden;
  position: absolute;
  z-index: 9999;
  top: calc(100% + 4px);
  background-color: variables.$ht-white;
  box-shadow: variables.$ht-box-shadow-low;
  border-radius: 2px;
  width: 100%;
  max-height: 209px;
  overflow-y: scroll;
}

.autocompleteItem {
  background-color: variables.$ht-white;
  padding: 14px 16px;
  border-bottom: 1px solid variables.$ht-navy-200;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &.active {
    background-color: variables.$ht-navy-100;
  }
}
