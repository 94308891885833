@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

.flag {
  font-size: 20px;

  &.red {
    color: variables.$ht-alert-red-700;
  }
}

.flagAdjustRight {
  display: flex;
  justify-content: flex-end;

  @include spacing.spacing(padding-right, 2);
}

.statsContainer {
  position: relative;
  background-color: variables.$ht-white;
  color: variables.$ht-navy-900;
  box-shadow: variables.$ht-box-shadow-medium;

  @include spacing.spacing(padding, 3);

  width: 420px;
  min-height: 168px;
  border-radius: 12px;
  border: 1px solid variables.$ht-navy-200;
}

.headerDisplay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;

  span {
    height: 15px;
  }
}

.completed {
  color: variables.$ht-success-green-500;
  font-size: 16px;

  i {
    font-size: 18px !important;
  }
}

.atIssue {
  color: variables.$ht-alert-red-700;
  font-size: 16px;

  i {
    font-size: 20px !important;
  }
}

.propertOwner {
  color: variables.$ht-navy-700;
  font-size: 11px;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
}
