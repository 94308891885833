@use 'ht-styleguide/build/spacing';
@use 'ht-styleguide/build/variables';

.groups_name {
  font-size: 16px;
  font-weight: variables.$ht-font-weight-medium;
  letter-spacing: -0.3px;
  color: variables.$ht-navy-800;
}

.groups_info {
  font-size: 14px;
  line-height: 20px;
  font-weight: variables.$ht-font-weight-regular;
  letter-spacing: -0.2px;
  color: variables.$ht-navy-800;
}

.panel {
  border: 1px solid variables.$ht-navy-200;
}

.tooltipGroup {
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 240px;
  @include spacing.spacing(padding, 2.5);

  > div:not(:last-child) {
    @include spacing.spacing(margin-bottom, 3);
  }
}

.templateGroupTooltipRow {
  div:not(:last-child) {
    @include spacing.spacing(margin-bottom, 3);
  }
}

.tableColumnAlignRight {
  text-align: right !important;

  [data-testid='StyleGuideSelectField'] {
    width: 100px;
    float: right;
  }
}

.arrowIcon {
  font-size: 10px;
  color: variables.$ht-navy-700 !important;
}

.editIconSmall {
  font-size: 16px;
}

.inputNumberIconSuppress {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.inputContainer {
  width: 115px !important;

  @extend .inputNumberIconSuppress;
}

.inputContainer_small {
  width: 70px !important;

  @extend .inputNumberIconSuppress;
}

.qtyInputLineItem {
  display: inline-block;
  width: 90px;
}

.skuSearchInput {
  flex: 1;
  min-width: 356px;
}

.hoverArea {
  flex: 1 1 auto;
}

.explicitHeight24 {
  height: 24px;
}
