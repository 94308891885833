@use 'ht-styleguide/build/grid';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

$basetool-header-height: variables.$ht-base-unit * 10;
$basetool-header-z-index: 10;

.adminHubContainer {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  :global {
    [data-id='tooltip'] {
      border: none !important;
      padding: unset !important;
      opacity: 1 !important;
    }
  }
}

.ContentTwoColumnContainerWithNav {
  position: relative;
  display: flex;
  flex-direction: row;

  /*
    `height: 100%` cannot be used to fill in the empty space as `height: 100%` will set the height to the
    height of the parent, which is the height of the viewport.
  */
  flex: 1;
  min-height: 0;
  width: 100%;

  .ContentTwoColumnContainerWithNav__left {
    // z-index needs to be higher than .ContentTwoColumnContainerWithNav__right's so that
    // the left column's sidebar will not be covered by the right column's table component's
    // pinned columns
    z-index: 2;
    // position: relative;
    // display: flex;
    // flex-direction: column;
    // height: 100%;
  }
  .ContentTwoColumnContainerWithNav__right {
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    min-height: 0;
    overflow-y: auto;
  }
}

// Needs to have menu zindex because of pagination
/* stylelint-disable no-descending-specificity */
.baseFeaturePage {
  :global {
    [class*='table-module_editMenu'] {
      z-index: 1;
      > ul > li {
        white-space: nowrap;
      }
    }
    // [class*="table-module_editIcon"] {}
  }
}

.rightContainerPortal {
  z-index: 1;
  position: sticky;
  width: 100%;
}

.rightContainerPortalTop {
  @extend .rightContainerPortal;

  top: 0;
}

.rightContainerPortalBottom {
  @extend .rightContainerPortal;

  bottom: 0;
}
