@use 'ht-styleguide/build/spacing';

.column {
  > *:not(:first-child):not(.checkbox) {
    @include spacing.spacing(margin-top, 4);
  }

  > .checkbox:not(:first-child) {
    @include spacing.spacing(margin-top, 6);
  }
}

.menuPosition {
  left: -70px;
  top: 40px;
  width: 200px;
}
