@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

$entry-height: 44px;
$showHideButtonSize: 24px;
$sidebarWidth: 280px;

/** Needs to be higher than right column content's z-index */
$sidebarZIndex: 5;

/** Wrap the button with a container for bigger clickable area */
.hideShowButtonContainer {
  position: absolute;
  top: 32px;
  right: calc($showHideButtonSize / -2);
}
.hideShowButton {
  width: $showHideButtonSize;
  height: $showHideButtonSize;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: variables.$ht-blue-500;
  &:hover {
    background-color: variables.$ht-blue-700;
  }
}

/* stylelint-disable no-descending-specificity */
.sidebar {
  display: flex;
  flex-direction: column;
  background-color: variables.$ht-dark-blue-700;
  color: variables.$ht-navy-000;
  width: $sidebarWidth;
  position: sticky;
  top: 0;
  left: 0;
  height: 100%;
  z-index: $sidebarZIndex;

  .accordion {
    // Override <Accordion /> styles
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    button {
      @include spacing.spacing(padding, 1 0);

      opacity: 0.7;
    }

    button:hover {
      opacity: 1;
      i {
        color: variables.$ht-white !important;
      }
      h5 {
        color: variables.$ht-white !important;
      }
    }

    h5 {
      text-transform: uppercase;
      color: variables.$ht-white;
      line-height: 18px;
      font-size: 12px;
      letter-spacing: 1.2px;
      opacity: 0.7;
    }
  }

  .callout {
    background-color: variables.$ht-dark-blue-500;
    color: rgba(variables.$ht-navy-000, 0.7);
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 12px;
    flex-shrink: 0;
  }

  > .body {
    overflow: auto;
    @include spacing.spacing(padding-bottom, 12);
    &.topScrolled {
      border-top: 1px solid rgba(variables.$ht-navy-000, 0.12);
    }
    &.bottomScrolled {
      border-bottom: 1px solid rgba(variables.$ht-navy-000, 0.12);
    }
    &.hideOverflowY {
      overflow-y: hidden;
    }
  }
}
/* stylelint-enable no-descending-specificity */

.divider {
  border-color: rgba(variables.$ht-navy-000, 0.3);
}

.section > .label {
  text-transform: uppercase;
  opacity: 0.7;
}

.entries {
  color: variables.$ht-navy-000;

  > .entry {
    @include spacing.spacing(padding, 0 2);

    display: flex;
    align-items: center;
    height: $entry-height;
    border-radius: 6px;
    cursor: pointer;
    position: relative;

    .icon {
      @include spacing.spacing(padding-right, 2);

      font-size: 20px;
    }

    &.active {
      background-color: variables.$ht-dark-blue-500;
    }

    &:not(.active):hover {
      background-color: rgba(variables.$ht-navy-000, 0.05);
    }

    .badge_base {
      position: absolute;
      right: 0;

      @include spacing.spacing(margin-right, 2);
    }
  }
}

.accordionBorder {
  border-bottom: 1px solid rgba(variables.$ht-navy-000, 0.3);
}
