@use 'ht-styleguide/build/variables';

.panel {
  border: 1px solid variables.$ht-navy-200;
}

.ctaButton {
  width: fit-content !important;
}

.memberTableCell {
  &.inactive {
    opacity: 0.4;
  }
}
