@use 'ht-styleguide/build/variables';

/****************************************
*  FormSubmitted
****************************************/
.successCheckmark {
  font-size: 40px;
}

.horizontalRule {
  border-top: 1px solid variables.$ht-navy-300;
  width: 100%;
  height: 1px;
}
