@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.headerBase {
  min-height: 64px;
  @include spacing.spacing(padding-left, 3);
  @include spacing.spacing(padding-right, 3);
  @include spacing.spacing(padding-top, 2.25);
  @include spacing.spacing(padding-bottom, 2.25);
  &.withBorder {
    border: 1px solid variables.$ht-navy-200;
  }
}

.hideHeaderBase {
  display: none;
}

.headerTopElement {
  background-color: variables.$ht-navy-000;
  position: relative;
  z-index: 2;
}

.headerBottomElement {
  z-index: 1; // Must be lower than headerTopElement
  @include spacing.spacing(margin-left, 1);
  @include spacing.spacing(margin-right, 1);
  @include spacing.spacing(padding-top, 0.75);
  @include spacing.spacing(padding-bottom, 0.75);

  transition: transform 0.1s linear;
}

.headerContainer {
  transition: height 0.1s linear;
}

.headerText {
  &.input {
    resize: none;
    overflow: hidden;
    width: 100%;
    border: none;
    @include spacing.spacing(padding-left, 2);
    @include spacing.spacing(padding-right, 2);
    @include spacing.spacing(padding-top, 0.75);
    @include spacing.spacing(padding-bottom, 0.75);

    cursor: pointer;
    transition: background-color 0.15s linear;
    &:hover {
      border: none;
      background-color: variables.$ht-navy-100;
    }

    &:focus {
      outline: 1px solid variables.$ht-blue-500;
      // outline: none;
    }

    &.error {
      outline: 1px solid variables.$ht-alert-red-500;
    }
  }
}

.headerTextNonEditable {
  @include spacing.spacing(margin-left, 2);
}

.subheaderText {
  @include spacing.spacing(padding-left, 2);
  @include spacing.spacing(padding-right, 2);
}

.leftContentCollapsed {
  display: flex;
  align-items: center;
  min-width: 0;
  .headerText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1; /* It allows this element to grow and shrink as needed. */
  }
}

.errorText {
  font-weight: variables.$ht-font-weight-regular;
  color: variables.$ht-alert-red-500;
}

.rightContentCollapsed {
  flex-shrink: 0;
  display: flex;
}

// Visibility
.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s linear;
  position: absolute;
}
