@use 'src/styles/global/variables';

/*
   Some of these values do exist in the styleguide, others don't.
 */
.draft {
  background-color: rgba(variables.$subdued-blue, 0.08);
  color: variables.$subdued-blue !important;
  border: 1px solid variables.$subdued-blue;
}

.ready_to_launch {
  background-color: rgba(variables.$neon-purple, 0.08);
  color: variables.$neon-purple !important;
  border: 1px solid variables.$neon-purple;
}

.upcoming {
  background-color: rgba(variables.$ht-blue-050, 0.08);
  color: variables.$ht-blue-050 !important;
  border: 1px solid variables.$ht-blue-050;
}

.not_started {
  background-color: rgba(variables.$ht-navy-900, 0.04);
  color: variables.$ht-navy-900 !important;
}

.unserviceable {
  background-color: rgba(variables.$ht-alert-red-700, 0.08);
  color: variables.$ht-alert-red-700 !important;
}
