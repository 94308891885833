@use 'sass:map';
@use 'ht-styleguide/build/spacing';
@use 'ht-styleguide/build/typography_placeholders';
@use 'src/styles/global/variables';

:root {
  --toastify-icon-color-error: #fff;
}

.htToastContainer {
  width: 900px !important;
  font-family: variables.$ht-font-family;

  :global {
    .Toastify__toast {
      width: 100%;
      border-radius: map.get(variables.$ht-panel-border-radius, small);
      @include spacing.spacing(padding-right, 5);

      color: inherit;
      @extend %h5;
    }

    .Toastify__toast--error {
      background: variables.$ht-alert-red-700;
      color: variables.$ht-white;
    }
  }
}

.htToastClose {
  position: absolute;
  @include spacing.spacing(right, 1.5);
}
