@use 'ht-styleguide/build/variables';

.panel {
  &.withBorder {
    border: 1px solid variables.$ht-navy-200;
  }
}

.uploadButton {
  width: fit-content !important;
}
