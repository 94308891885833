@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.arrowIconsContainer {
  display: flex;
  flex-direction: column;
  @include spacing.spacing(margin-left, 1.5);

  font-size: 8px;
}

.arrowUp {
  border-bottom: 6px solid variables.$ht-navy-300;
}

.arrowDown {
  border-top: 6px solid variables.$ht-navy-300;
}

.arrowDown,
.arrowUp {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
}

:global {
  .MuiTableSortLabel-root {
    &.Mui-active {
      :local {
        .arrowUp {
          &.active {
            border-bottom-color: variables.$ht-navy-700;
          }
        }
        .arrowDown {
          &.active {
            border-top-color: variables.$ht-navy-700;
          }
        }
      }
    }
  }
}
