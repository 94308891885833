@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.error_page {
  background-color: variables.$ht-dark-blue-500;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.error_container {
  @include spacing.spacing(padding-top, 5);
  @include spacing.spacing(padding-bottom, 5);

  background-color: variables.$ht-navy-300;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: variables.$ht-alert-red-500;
    font-size: 80px;
    margin: 0;
  }
}

.error_container_display {
  @include spacing.spacing(padding, 3);
  @include spacing.spacing(margin, 5);

  background-color: variables.$ht-white;
  border: 1px solid variables.$ht-dark-blue-700;
  border-radius: 15px;
  width: 900px;
}

.error_title {
  @include spacing.spacing(padding-top, 5);

  font-weight: variables.$ht-font-weight-bold;
  font-size: 22px;
  color: variables.$ht-navy-700;
}

.error_message {
  @include spacing.spacing(padding-top, 2);

  font-size: 16px;
}
