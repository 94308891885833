@use 'src/styles/global/variables';

.flagIconTable {
  i {
    font-size: 20px;
    color: variables.$ht-alert-red-700;
  }
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  min-height: 75vh;
  justify-content: center;
}
