@use 'src/styles/global/variables';

.sharedText {
  line-height: 16px;
  letter-spacing: -0.08px;
  font-weight: variables.$ht-font-weight-medium;
  color: variables.$ht-teal-500;
}

.actionGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 16px;

  div {
    cursor: pointer;
  }

  &.sm {
    .stringAction {
      /* Styles for small string action */
      @extend .sharedText;

      font-size: 12px;
    }

    .iconAction {
      /* Styles for small icon action */
      i {
        font-size: 14px !important;
      }
    }
  }

  &.med {
    .stringAction {
      /* Styles for medium string action */
      @extend .sharedText;

      font-size: 12px;
    }

    .iconAction {
      /* Styles for medium icon action */
      i {
        font-size: 16px !important;
      }
    }
  }

  &.lg {
    height: 24px;

    .stringAction {
      /* Styles for large string action */
      @extend .sharedText;

      font-size: 16px;
    }

    .iconAction {
      /* Styles for large icon action */
      i {
        font-size: 20px !important;
      }
    }
  }
}
