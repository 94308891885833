/* stylelint-disable declaration-block-no-duplicate-properties */
@use 'ht-styleguide/build/grid';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

$ht-modal-min-height: 200px;

.overlay {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: variables.$ht-transparent-modal;
  z-index: variables.$ht-z-index-modal;
}

.slideUp {
  animation: slideup 0.2s ease-in-out;
}

.slideDown {
  animation: slidedown 0.2s linear;
}

@keyframes slideup {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slidedown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.modalClose {
  z-index: 1;
  @include spacing.spacing(top, 4);
  @include spacing.spacing(right, 4);

  font-size: 18px;
  color: variables.$ht-navy-300;
  cursor: pointer;

  &:hover {
    color: variables.$ht-navy-700;
  }

  @include grid.with-md-grid {
    @include spacing.spacing(top, 5);
    @include spacing.spacing(right, 5);
  }
}

.commonModalStyles {
  overflow: auto;
  position: fixed;
  background-color: variables.$ht-navy-000;
  outline: none;
  width: 100%;
  height: 100%;
  @include spacing.spacing(padding, 2 2 0);

  @include grid.with-md-grid {
    @include spacing.spacing(padding, 3 3 0);

    &.slideUp {
      animation: none;
    }

    &.slideDown {
      animation: none;
    }

    position: absolute;
    height: inherit;
    top: 20%;
    left: 50%;
    padding: 0;
    min-height: $ht-modal-min-height;
    margin-top: $ht-modal-min-height * -0.5;
    transform: translate(-50%, 0%);
  }
}

.modal {
  height: auto;

  @include grid.with-md-grid {
    padding-bottom: 0;
  }
}

.footerContainer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: inherit;
  right: 0;
  left: 0;
  @include spacing.spacing(padding, 4);

  background-color: variables.$ht-navy-000;

  @include grid.with-md-grid {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include spacing.spacing(margin-top, 5);

    padding: 0;
  }
}

.footerElement1 {
  font-weight: variables.$ht-font-weight-medium;
}

// Large modal
.modalLarge {
  @include grid.with-md-grid {
    @include spacing.spacing(padding, 6);
    @include spacing.spacing(max-width, 110);
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    @include spacing.spacing(margin-bottom, 1);
  }

  .subHeader {
    @include spacing.spacing(padding-bottom, 4);

    @include grid.with-md-grid {
      @include spacing.spacing(padding-bottom, 6);
    }
  }

  .footerElementContainer {
    @include grid.with-md-grid {
      display: flex;
    }
  }

  .footerElement2,
  .footerElement3 {
    @include spacing.spacing(margin-top, 2);

    @include grid.with-md-grid {
      margin-top: 0;
      @include spacing.spacing(margin-left, 2);
    }
  }
}

// Medium modal
.modalMedium {
  @include grid.with-md-grid {
    @include spacing.spacing(padding, 5);
    @include spacing.spacing(max-width, 80);
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    @include spacing.spacing(margin-bottom, 1);
  }

  .subHeader {
    @include spacing.spacing(padding-bottom, 5);
  }

  .footerElementContainer {
    @include grid.with-md-grid {
      display: flex;
    }
  }

  .footerElement2,
  .footerElement3 {
    @include spacing.spacing(margin-top, 2);

    @include grid.with-md-grid {
      margin-top: 0;
      @include spacing.spacing(margin-left, 2);
    }
  }
}

// Small modal
.modalSmall {
  @include spacing.spacing(padding, 4 4 0);

  @include grid.with-md-grid {
    @include spacing.spacing(max-width, 47);
    @include spacing.spacing(padding, 4);
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
  }

  .header {
    font-size: 24px;
  }

  .subHeader {
    font-size: 16px;
    @include spacing.spacing(padding-bottom, 4);
  }

  .footerContainer {
    display: block;
  }

  .footerElementContainer {
    display: block;
  }

  .footerElement2,
  .footerElement3 {
    @include spacing.spacing(margin-top, 2);
  }
}
/* stylelint-enable declaration-block-no-duplicate-properties */
