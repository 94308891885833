@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

/*
  LineItemBreakdown
*/
.itemInfo {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.itemAmount {
  @include spacing.spacing(margin-left, 3);

  white-space: nowrap;
}

.itemNameContainer {
  &.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

/*
  CouponLineItem
*/
.couponNameContainer {
  display: flex;
  align-items: center;

  .couponCloseIcon {
    color: variables.$ht-navy-700;
    font-size: 12px;
    @include spacing.spacing(margin-left, 1);
  }
}
