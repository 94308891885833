@use 'sass:map';
@use 'ht-styleguide/build/spacing';
@use 'ht-styleguide/build/grid';
@use 'src/styles/global/variables';

.hint {
  background: variables.$ht-navy-100;
  border-radius: map.get(variables.$ht-panel-border-radius, medium);
  @include spacing.spacing(padding, 2);

  line-height: 1.2;
  @include spacing.spacing(margin-top, 1);
}

.hintIcon {
  display: inline-block;
  font-size: 16px;

  @include grid.with-md-grid {
    font-size: 20px;
  }
}
