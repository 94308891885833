@use 'ht-styleguide/build/variables';

.subtextSuccess {
  color: variables.$ht-success-green-700;
}

.refreshIcon {
  font-size: 20px;
  vertical-align: middle;
  padding-right: 2px;
}
