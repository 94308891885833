@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.checkboxContainer {
  @include spacing.spacing(margin-left, 2);
}

.check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxWrapper {
  .quantity {
    display: block;
    width: 80px;
  }
  .quantityWrapper {
    @include spacing.spacing(padding-top, 0.5);
    @include spacing.spacing(margin-left, 5);
  }
}

.answer {
  position: relative;

  .answerHintIcon {
    position: absolute;
    right: 0;
    top: 7px;

    &.answerHintIconInput {
      top: -5px;
    }
  }
}

.preQuestionCheckbox {
  position: relative;

  &.preQuestionCheckboxError {
    border: 1px solid variables.$ht-alert-red-500;
    border-radius: 2px;
    @include spacing.spacing(padding, 2);
  }
}

.contentOverlay {
  opacity: 0.5;
  pointer-events: none;
}

.qa_notification {
  @include spacing.spacing(margin-top, 4);
  @include spacing.spacing(padding, 2);

  border: 1px solid variables.$ht-navy-300;
  background-color: variables.$ht-navy-200;

  ul {
    list-style-type: disc;
    li {
      @include spacing.spacing(padding-bottom, 1);
      @include spacing.spacing(margin-left, 4);
    }
  }
}
