@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.listContainer {
  @include spacing.spacing(width, 46);

  height: 100%;
  overflow-y: auto;
}

.serviceContainer {
  display: block;
  position: relative;
  @include spacing.spacing(padding, 2.5 2);

  opacity: 0.5;
  cursor: default;

  &:hover {
    opacity: 0.8;
    background-color: variables.$ht-navy-200;
  }

  &.active {
    opacity: 1;
    background-color: variables.$ht-navy-200;
  }
}

.currentServiceMarker {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: variables.$ht-teal-500;
  @include spacing.spacing(width, 0.5);
}
