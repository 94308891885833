$menuPanelAdjsutmentTop: 28px;

.wrapper {
  position: relative;
}

.menuPanel {
  left: 0;
  right: unset;
  top: $menuPanelAdjsutmentTop;
}
