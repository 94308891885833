@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.message {
  @include spacing.spacing(padding-top, 2);

  .messageContent {
    font-size: 15px;
    line-height: 30px;
    border: 1px solid variables.$ht-navy-300;
    background-color: variables.$ht-navy-050;

    @include spacing.spacing(padding, 2);
    @include spacing.spacing(margin-top, 1);
  }

  .messageTitle {
    font-weight: variables.$ht-font-weight-bold;
    font-size: 18px;

    & + .messageContent {
      @include spacing.spacing(padding-top, 2);
    }
  }
}
