@use 'ht-styleguide/build/variables';

.panel {
  border: 1px solid variables.$ht-alert-red-700;
  background-color: variables.$ht-alert-red-100;
}
.darkRedText {
  color: variables.$ht-alert-red-900;
}
.lightRedText {
  color: #845e5d;
}
