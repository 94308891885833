@use 'ht-styleguide/build/variables';

.ownerAvatar {
  color: variables.$ht-white !important;
  background-color: variables.$ht-blue-500 !important;
}

.nonOwnerAvatar {
  color: variables.$ht-blue-700 !important;
  background-color: variables.$ht-blue-100 !important;
  border: 1px solid variables.$ht-blue-700 !important;
}

.small {
  width: 36px !important;
  height: 36px !important;
  font-size: 18px !important;
}

.medium {
  width: 48px !important;
  height: 48px !important;
  font-size: 24px !important;
}
