@use 'src/styles/global/variables';

.icon {
  font-size: 20px;
}

.iconCheckmark {
  color: variables.$ht-navy-300;

  &.completed {
    color: variables.$ht-success-green-500;
  }
}
