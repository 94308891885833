@use 'variables';

/*************
  Helpers
**************/

/*
  - Spacing
  - Typography
  - Text alignment
  - Font weights
  - Element
  - Position
  - Flexbox
  - Colors
  - Background Colors
  - Others
*/

// Spacing
.fullheight {
  height: 100%;
}

.fullwidth {
  width: 100%;
}

.width-auto {
  width: auto;
}

// Typography
.nowrap {
  white-space: nowrap;
}

.inline {
  display: inline-block;
}

.opacity70 {
  opacity: 0.7;
}

// Text alignment
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// Font weights
.text-weight-regular {
  font-weight: variables.$ht-font-weight-regular;
}

.text-weight-med {
  font-weight: variables.$ht-font-weight-medium;
}

.text-weight-bold {
  font-weight: variables.$ht-font-weight-bold;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.error {
  color: variables.$ht-alert-red-500;
}

.line-through {
  text-decoration: line-through;
}

.cursorPointer {
  cursor: pointer;
}

// Element
.margin-auto {
  margin: auto;
}

.cursor {
  cursor: pointer;
}

.focusStyles {
  &:focus {
    border: 1px solid variables.$ht-blue-700;
  }
}

.clear {
  clear: both;
}

.hidden {
  visibility: hidden;
  height: 0;
  line-height: 0;
}

.visible {
  visibility: visible;
  opacity: 1;
}

.invisible {
  visibility: hidden;
  opacity: 0;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointerEventsNone {
  pointer-events: none;
}

// Position
.position {
  &.relative {
    position: relative;
  }
  &.absolute {
    position: absolute;
  }
}

// Flexbox
.flex {
  display: flex;
  &.align-items-center {
    align-items: center;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.align-items-flex-start {
    align-items: flex-start;
  }
  &.align-items-baseline {
    align-items: baseline;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-space-around {
    justify-content: space-around;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-flex-end {
    justify-content: flex-end;
  }
  &.justify-content-right {
    justify-content: right;
  }
  &.flex-direction-row {
    flex-direction: row;
  }
  &.flex-direction-column {
    flex-direction: column;
  }
  &.flex-inline {
    display: inline-flex;
  }
}
.flex1 {
  flex: 1;
}

// Plain Button
.plainButton {
  appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
  margin: 0;
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

// Colors
.n300 {
  color: variables.$ht-navy-300;
}
.n700 {
  color: variables.$ht-navy-700;
}
.n800 {
  color: variables.$ht-navy-800;
}
.n900 {
  color: variables.$ht-navy-900;
}
.n000 {
  color: variables.$ht-navy-000;
}
.teal {
  color: variables.$ht-teal-500;
}
.white {
  color: variables.$ht-white;
}
.blue700 {
  color: variables.$ht-blue-700;
}
.blue500 {
  color: variables.$ht-blue-500;
}
.blue300 {
  color: variables.$ht-blue-300;
}
.alertRed700 {
  color: variables.$ht-alert-red-700;
}
.alertRed500 {
  color: variables.$ht-alert-red-500;
}
.successGreen700 {
  color: variables.$ht-success-green-700;
}
.successGreen500 {
  color: variables.$ht-success-green-500;
}
.deepYellow700 {
  color: variables.$ht-deep-yellow-700;
}

// Background Colors
.bg-n100 {
  background-color: variables.$ht-navy-100;
}

// Others
.border-top {
  border-top: 1px solid variables.$ht-navy-100;
}

.border-top-2 {
  border-top: 1px solid variables.$ht-navy-200;
}

.border-top-3 {
  border-top: 1px solid variables.$ht-navy-300;
}

.border-bottom {
  border-bottom: 1px solid variables.$ht-navy-100;
}

.border-bottom-2 {
  border-bottom: 1px solid variables.$ht-navy-200;
}

.border-right {
  border-right: 1px solid variables.$ht-navy-200;
}

.border {
  border: 1px solid variables.$ht-navy-200;
}

.border-radius-4 {
  border-radius: 4px;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-underline {
  text-decoration: underline;
}
