@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.underline {
  text-decoration: underline;
}

.columnOneLineItemUpdateSidebar {
  display: flex;
  align-items: center;
  flex: 0 0 120px;

  @include spacing.spacing(margin-bottom, 0.5);
}

.columnTwoLineItemUpdateSidebar {
  display: flex;
  align-items: center;
  flex: 1;

  @include spacing.spacing(margin-bottom, 0.5);

  > div {
    flex: 1;
  }
}

.singleColumnWithBorder {
  border-top: 1px solid variables.$ht-navy-300;

  @include spacing.spacing(margin-top, 1);
}

.sideSheetEditContainer {
  :global {
    .react-select__control--is-disabled {
      background-color: variables.$ht-white !important;
    }
  }

  input:disabled {
    background-color: variables.$ht-white !important;
  }

  div[class*='Input_disabled'] {
    border: unset !important;
    background-color: unset !important;
    opacity: 1 !important;
  }
}
