@use 'ht-styleguide/build/variables';

.successTakeOverContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: variables.$ht-white;

  .subtextSuccess {
    color: variables.$ht-success-green-700;
  }
}
