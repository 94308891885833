@use 'sass:map';
@use './variables';

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

html {
  overflow-y: auto;
}

body {
  font-family: variables.$ht-font-family;
  font-weight: variables.$ht-font-weight-regular;
  font-style: normal;
  color: variables.$ht-navy-900;
  input,
  button,
  textarea {
    font-family: variables.$ht-font-family;
  }
}

#app {
  min-height: 100%;
  height: 100%;
  background-color: variables.$ht-white;
}

.react-select__menu {
  z-index: variables.$ht-z-index-request-loader !important;
}
