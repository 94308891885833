@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;

  @include spacing.spacing(padding, 0 0.5);
  @include spacing.spacing(padding-top, 1px);
}

.badgeMultipleChars {
  border-radius: 20px;
  min-width: auto;
}

.success {
  background: variables.$ht-teal-500;
  color: variables.$ht-white;
}

.primary {
  background: variables.$ht-blue-700;
  color: variables.$ht-white;
}

.neutral {
  background: variables.$ht-navy-900;
  color: variables.$ht-white;
}

.warning {
  background: variables.$ht-deep-yellow-700;
  color: variables.$ht-white;
}

.muted {
  background: variables.$ht-navy-300;
  color: variables.$ht-white;
}
