@use 'src/styles/global/variables';

.actionIcon {
  color: variables.$ht-navy-000;
  font-size: 20px;
}

.menu {
  position: relative;
  top: 20px;
}
