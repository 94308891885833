@use 'ht-styleguide/build/spacing';
@use 'src/styles/global/variables';

/**************************************************
  <DetailsBlock />
**************************************************/
.list {
  list-style: disc;
  list-style-position: inside;
}

/**************************************************
  <DeviceBlock />
**************************************************/
.productImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid variables.$ht-navy-200;
  @include spacing.spacing(margin-right, 1);
}
