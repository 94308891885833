@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.main {
  width: 100%;
}

.dashboard {
  background-color: variables.$ht-navy-050;
}

// Customer Section
.customerDetailsRow {
  width: 100%;
  margin: 0 auto;

  &:last-child {
    @include spacing.spacing(margin-top, 4);
  }
}

.textBottomRow {
  display: flex;
  align-items: center;
  @include spacing.spacing(gap, 1);
}

.customerButtons {
  display: flex;
  & > :last-child {
    @include spacing.spacing(margin-left, 2);
  }
}

.informationCards {
  display: flex;
  flex-wrap: wrap;
  & > :not(:last-child) {
    @include spacing.spacing(margin-right, 6);
  }
}

.starIcon {
  font-size: 14px;
  color: variables.$ht-deep-yellow-500;
  @include spacing.spacing(margin-right, 0.5);
}

// Stats Section
.statsSection {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px;
  @include spacing.spacing(margin-bottom, 3);

  .statsBlock {
    width: 100%;
    text-align: center;
  }
}

.firstColumn,
.secondColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

// Membership Section (membership, orders, and device tables)

.membershipBenefitsTable,
.ordersTable,
.devicesTable {
  width: 100%;
  & .tableHeader {
    border-bottom: variables.$ht-divider-200;
    & .orderHeaders {
      text-align: left;
      &:first-child {
        width: 140px;
      }
      &:nth-child(2) {
        width: 200px;
      }
      &:nth-child(3) {
        width: 400px;
      }
      &:nth-child(4) {
        width: 100px;
      }
    }
    & .membershipHeaders {
      &:first-child {
        text-align: left;
      }
    }
    & .deviceHeaders {
      text-align: left;
      &:first-child {
        width: 60px;
      }
      &:nth-child(2) {
        width: 100px;
      }
      &:nth-child(3) {
        width: 200px;
      }
      &:nth-child(4) {
        width: 150px;
      }
      &:last-child {
        width: 150px;
      }
    }

    & > * {
      @include spacing.spacing(padding-bottom, 3);
    }
  }

  & td {
    padding-top: 24px;
    text-align: left;
    word-wrap: break-word;

    &.membershipIcons:nth-child(2) {
      text-align: center;
    }
  }
}

.icon {
  font-size: 20px;
  color: variables.$ht-navy-200;

  &.active {
    color: variables.$ht-blue-700;
  }
}

// Orders Section

.successGreen {
  color: variables.$ht-success-green-700;
}

// Zillow Section

// Device Section

.deviceRow {
  vertical-align: middle;
}

.customerSection {
  border-bottom: variables.$ht-divider-200;
}

.deviceImage {
  width: 48px;
  height: 48px;
  display: inline-block;
  object-fit: cover;
  border-radius: 4px;
  @include spacing.spacing(margin-right, 3);
}
