@use 'ht-styleguide/build/variables';

.sectionHeader {
  border-bottom: 1px solid variables.$ht-navy-300;
}

.sectionIcon {
  font-size: 24px;
}

.icon {
  &.groupCompletion {
    font-size: 32px;
  }
  &.lineItem {
    font-size: 24px;
  }
  &.complete {
    color: variables.$ht-success-green-500;
  }
  &.incomplete {
    color: variables.$ht-navy-300;
  }
}
