@use 'src/styles/global/variables';
@use 'ht-styleguide/build/spacing';

@mixin background-with-opacity($color, $opacity: 0.1) {
  background-color: rgba($color, $opacity);
}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid variables.$ht-navy-200;
  border-radius: 12px;
  background-color: variables.$ht-white;

  @include spacing.spacing(padding, 2);

  .actionIcon {
    position: absolute;
    @include spacing.spacing(right, 2);
    @include spacing.spacing(top, 2);

    color: variables.$ht-navy-300;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .imageContainer {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    .iconBackground {
      display: flex;
      align-items: center;
      justify-content: center;
      @include background-with-opacity(variables.$ht-navy-900);

      border-radius: 50%;
      width: 32px;
      height: 32px;
    }

    .icon {
      font-size: 16px;
      color: variables.$ht-navy-700;
    }

    &.iconVariant-purple {
      .icon {
        color: variables.$neon-purple;
      }

      .iconBackground {
        @include background-with-opacity(variables.$neon-purple);
      }
    }

    &.iconVariant-deep-purple {
      .icon {
        color: variables.$deep-purple;
      }

      .iconBackground {
        @include background-with-opacity(variables.$deep-purple);
      }
    }

    &.iconVariant-red {
      .icon {
        color: variables.$ht-alert-red-700;
      }

      .iconBackground {
        @include background-with-opacity(variables.$ht-alert-red-700);
      }
    }

    &.iconVariant-blue {
      .icon {
        color: variables.$ht-blue-500;
      }

      .iconBackground {
        @include background-with-opacity(variables.$ht-blue-500);
      }
    }

    &.iconVariant-light-blue {
      .icon {
        color: variables.$ht-blue-050;
      }

      .iconBackground {
        @include background-with-opacity(variables.$ht-blue-050);
      }
    }

    &.iconVariant-subdued-blue {
      .icon {
        color: variables.$subdued-blue;
      }

      .iconBackground {
        @include background-with-opacity(variables.$subdued-blue);
      }
    }

    &.iconVariant-yellow {
      .icon {
        color: variables.$ht-deep-yellow-700;
      }

      .iconBackground {
        @include background-with-opacity(variables.$ht-deep-yellow-700);
      }
    }

    &.iconVariant-green {
      .icon {
        color: variables.$ht-success-green-500;
      }

      .iconBackground {
        @include background-with-opacity(variables.$ht-success-green-500);
      }
    }

    &.iconVariant-teal {
      .icon {
        color: variables.$ht-teal-500;
      }

      .iconBackground {
        @include background-with-opacity(variables.$ht-teal-500);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .label {
      opacity: 0.7;

      // one-off
      font-size: 9px;
      font-weight: variables.$ht-font-weight-medium;
      line-height: 1.333333;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  &:not(.prominent) {
    .imageContainer {
      @include spacing.spacing(margin-right, 1.5);
    }
  }

  &.prominent {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .imageContainer {
      display: flex;
      @include spacing.spacing(margin-bottom, 2);

      .iconBackground {
        width: 56px;
        height: 56px;
      }

      .icon {
        font-size: 24px;
      }
    }
  }

  &.hover {
    cursor: pointer;
    &:hover {
      background-color: variables.$ht-blue-100;
    }
  }
}
