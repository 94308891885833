@use 'ht-styleguide/build/grid';

/*
  Admin Grid has different behaviors. Here are the overrides.
*/
.grid {
  max-width: none !important;

  @media screen and (min-width: grid.$grid-xl-begin-margin-auto) {
    margin: 0 !important;
    margin-left: grid.$grid-lg-sideMargin !important;
    margin-right: grid.$grid-lg-sideMargin !important;
  }
}

.grid-full-width {
  max-width: none !important;
}
