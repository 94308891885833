@use 'ht-styleguide/build/spacing';
@use 'ht-styleguide/build/grid';
@use 'src/styles/global/mixins';
@use 'src/styles/global/variables';

// See MduGrid styles at src/features/MultiDwellingUnits/Parts/Grid/mduGrid.styles.scss
// We have to apply negative margins to the subheader to make the bottom border stretch to the edge of the page
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid variables.$ht-navy-200;
  z-index: 1;

  // Overcome grid margins
  @include grid.use-custom-margins(-32px);

  padding-left: 32px;
  padding-right: 32px;

  // Sticky subheader
  position: sticky;
  top: 0;
  background-color: white;
}

.buttonCreateNote {
  width: fit-content !important;
}

.panel {
  &.withBorder {
    border: 1px solid variables.$ht-navy-200;
  }
}

// Custom text style is ok here since it's special case
.smallText {
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.16px;
}

.innerNoteContainer {
  max-width: 712px;
}

.attachmentTableRow {
  border-bottom: 1px solid variables.$ht-navy-200;
  cursor: pointer;
}

.td {
  width: 100%;
}

.dividerLine {
  border-bottom: 1px solid variables.$ht-navy-300;
  width: 100%;
}
.dividerText {
  text-align: center;
  flex: 1;
  white-space: nowrap;
  text-transform: uppercase;
}

.noteContainer {
  .noteLineItem {
    &:last-of-type {
      margin-bottom: spacing.spacing-value(medium1, d);
      // margin-bottom: 100px;
    }
  }
}

.downloadIcon {
  font-size: 20px;
}

.newNoteIcon {
  font-size: 16px;
}
