.iconComfortable {
  font-size: 18px;
}

.iconCompact {
  font-size: 16px;
}

.iconImageComfortable {
  height: 18px;
}

.iconImageCompact {
  height: 16px;
}

.checkboxClassesOverride {
  display: flex;
  justify-content: center;
}

.checkboxWrapperOverride {
  padding: 0 !important;
}

.checkboxBoxOverride {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;
  &.compactCheckbox {
    height: 16px !important;
    width: 16px !important;
  }
}
