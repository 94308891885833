@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.checklistTitleIcon,
.checklistLineItemIcon {
  font-size: 14px;
}

.checklistLineItemIcon {
  color: variables.$ht-navy-700;
}

.checklistLineItem {
  @include spacing.spacing(padding, 1.75 1.5);

  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid variables.$ht-navy-200;
  &.editable {
    cursor: pointer;
  }

  &:hover {
    background-color: variables.$ht-navy-100;

    .underlineOnHover {
      text-decoration: underline;
    }
  }
}

.dragHandleWrapper {
  display: flex;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  @include spacing.spacing(left, -3.5);

  height: 100%;
  visibility: hidden;

  &.active {
    visibility: visible;
    &:hover {
      cursor: grab;
    }
  }
}

.dragHandleIcon {
  font-size: 20px;
  color: variables.$ht-navy-300;
  // horizontal padding is important. The element should bleed into the group name
  // so that the "mouseIn" state maintains while hovering over the drag handle icon.
  @include spacing.spacing(padding, 0.5 1);
}
