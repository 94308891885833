@use 'src/styles/global/variables';

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: variables.$ht-navy-000;
  z-index: 4;
}
