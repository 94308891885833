@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.editorContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentEditor {
  width: 100%;
  height: 100%;
  border: 1px solid variables.$ht-blue-700;
  border-radius: 8px;
  position: relative;

  :focus-visible {
    outline: none;
  }

  div[contenteditable='true'] {
    @include spacing.spacing(padding, 1);

    min-height: 88px;

    p {
      font-size: 14px !important;
    }

    [data-lexical-text='true'] {
      font-size: 14px !important;
    }
  }
}
